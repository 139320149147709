
.loader-containing-wrapper{
    /* position: relative; */
    background-image: linear-gradient(
      360deg,
      var(--backgroundColor),
      var(--gradientColor)
    );
    width: 100%;
    height: 100%;
    margin: auto;
    align-items: center;
    z-index: 101;
  }

  .loader-container {
    /* width: 100%;
    height: 100%;
    position: absolute;  */
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
      360deg,
      var(--backgroundColor),
      var(--gradientColor)
    );
  }

    .loader-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
    }

    .loader-outline {
      width: 15rem;
      border-radius: 100px;
      height: 25px;
      border: 2px solid white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
      background-color: rgba(0, 0, 0, 0.4);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .loader-progress {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 100px;
      background-color: #57ca85;
    }

    .loader-text {
      text-align: center;
      color: black;
      font-size: 12px;
      z-index: 102;
    }