@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400&display=swap");

.rules-menu-container,
.ar-instructions-help-button,
.helloar-view-in-your-own-space-button {
  display: none !important;
}

html {
  font-size: 16px;
  font-family: "Work Sans", sans-serif;
}

.otp-input {
  width: 3rem !important;
  height: 3rem !important;
  padding: 0.3rem 0.7rem;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotate 2s linear infinite;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.overlay-custom-class-name {
  background-color: transparent !important;
  backdrop-filter: blur(5px);
}

#uploadButton:active {
  transform: scale(0.97);
  border: none;
  outline: none;
}

@media (max-device-width: 1000px) and (orientation: landscape) {
  html {
    font-size: 10px;
  }
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
